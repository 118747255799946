export const FEATURES = {
	// core
	locations: true,
	vehicles: true,

	// optional but need customers to be true
	rentalReservations: true,
	rentalAgreements: true,
	trainingAgreements: false,
	leaseAgreements: true,

	// optional
	staff: true,
	customers: true,
	suppliers: true,
	vehicleCategories: true,
	vehicleRoadTaxes: true,
	vehicleRoadWorthyChecks: true,
	vehicleMaintenances: true,
	vehicleDamages: true,
};
